<template>
    <div class="create-content create-table">
        <div class="create-content-top">
            <div class="create-content-left">
                <el-button type="primary" plain @click="importPlacement" style="margin-right: 10px">导入数据</el-button>
                <input type="file" ref="excelInput" accept="excel/*" title="" @change="changeExcel($event)" style="display: none;">
                <el-button type="primary" plain @click="exportPlacement">导出数据</el-button>
                <el-button type="primary" plain @click="downloadPlacementTemplate">下载模板</el-button>
            </div>
            <div class="create-content-right">
                <label class="label-name">投放位置名称</label>
                <el-input type="text" v-model="advertising_name" placeholder="请输入投放位置名称" class="name-input"></el-input>
            </div>
        </div>
        <el-table :data="placementTable" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%"  size="medium" border
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column prop="sku_name" align="center" label="规格名称" width="420px">
                <template slot-scope="scope">
                    <div style="display: flex;cursor: pointer;" @click="scope.row.isShowImg = !scope.row.isShowImg">
                        <img src="../../../../assets/images/admin/tuijian.png" alt="" class="icon">
                        <span class="text">{{scope.row.sku_name}}</span>
                        <div class="one-click-creativity">一键创意</div>
                    </div>
                    <div v-show="scope.row.isShowImg" class="ad-img">
                        <img :src="scope.row.img" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="site" align="center" label="站点"></el-table-column>
            <el-table-column prop="exposure" align="center" label="曝光量级">
                <template slot-scope="scope">
                    <el-input
                            v-model="scope.row.exposure"
                            :ref="`placementTable_exposure_${scope.$index}`"
                            style="width: 100%"
                            class="input-error"
                            @blur="saveItem(scope.row.exposure)">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column prop="click_unit_price" align="center" label="点击单价">
                <template slot-scope="scope">
                    <el-input-number
                            v-model="scope.row.click_unit_price"
                            :ref="`placementTable_click_unit_price_${scope.$index}`"
                            @change="inputVal(scope.row)"
                            :controls="false"
                            :precision="1"
                            style="width: 100%"
                            class="input-error"
                            @blur="saveItem(scope.row.click_unit_price)">
                    </el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="click_rate" align="center" label="点击率（%）">
                <template slot-scope="scope">
                    <el-input-number
                            v-model="scope.row.click_rate"
                            :ref="`placementTable_click_rate_${scope.$index}`"
                            @change="inputVals"
                            style="width: 100%"
                            @blur="saveItem(scope.row.click_rate)"
                            :min="0.01"
                            :max="100"
                            :controls="false"
                            :precision="2"
                            class="input-error">
                    </el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="ecpm" align="center" label="ECPM（元）">
                <template slot-scope="scope">
                    <el-input-number
                            v-model="scope.row.ecpm"
                            :ref="`placementTable_ecpm_${scope.$index}`"
                            style="width: 100%"
                            @blur="saveItem(scope.row.ecpm)"
                            :min="0.01"
                            :max="99999999"
                            :controls="false"
                            :precision="2"
                            class="input-error">
                    </el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="prompt" align="center" label="提示&建议">
                <template slot-scope="scope">
                    <el-input
                            v-model="scope.row.prompt"
                            style="width: 100%">
                    </el-input>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {advertisingInfo, advertisingSetCache, advertisingImportData} from '@/utils/apis'

    export default {
        name: "PlacementCreateStepOne",
        data() {
            return {
                //投放位置名称
                advertising_name: '',
                //创建投放位置数据
                placementTable: [
                    {
                        id: 1,
                        sku_name: '1080X1920图文（ADX腾讯视频闪屏）-CPC',
                        img: require('../../../../assets/images/admin/placement/1.png'),
                        isShowImg: true,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 2,
                        sku_name: '1080X1920视频（ADX腾讯视频闪屏）-CPC',
                        img: require('../../../../assets/images/admin/placement/1.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 3,
                        sku_name: '1080X1920图文（ADX-QQ空间等闪屏广告）-CPC',
                        img: require('../../../../assets/images/admin/placement/2.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 4,
                        sku_name: '960X540图文（ADX-腾讯视频大图）-CPC',
                        img: require('../../../../assets/images/admin/placement/3.jpeg'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 5,
                        sku_name: '1280X720视频（ADX手Q横版视频）-CPC',
                        img: require('../../../../assets/images/admin/placement/4.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 6,
                        sku_name: '720X1280视频（ADX手Q竖版视频）-CPC',
                        img: require('../../../../assets/images/admin/placement/5.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 7,
                        sku_name: '230X152图文（ADX腾讯新闻原生三小图）-CPC',
                        img: require('../../../../assets/images/admin/placement/6.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 8,
                        sku_name: '1280X720视频（ADX联盟横版视频)-CPC',
                        img: require('../../../../assets/images/admin/placement/7.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 9,
                        sku_name: '240X180三图一文（ADX 手Q浏览器原生）-CPC',
                        img: require('../../../../assets/images/admin/placement/8.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 10,
                        sku_name: '640X360图文（ADX-腾讯新闻信息流大图）-CPC',
                        img: require('../../../../assets/images/admin/placement/9.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 11,
                        sku_name: '微信960X334图片（ADX-小程序内广告）-CPC',
                        img: require('../../../../assets/images/admin/placement/10.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 12,
                        sku_name: '微信960*540图片（ADX-公众号优雅大图）-CPC',
                        img: require('../../../../assets/images/admin/placement/11.jpeg'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 13,
                        sku_name: '1080X1920（ADX联盟开屏）-CPC',
                        img: require('../../../../assets/images/admin/placement/12.png'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 14,
                        sku_name: '1000X560图文（ADX手Q-空间好友动态Feeds）-CPC',
                        img: require('../../../../assets/images/admin/placement/13.jpeg'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 15,
                        sku_name: '1280X720＋300X300两图两文（ADX联盟-原生广告）-CPC',
                        img: require('../../../../assets/images/admin/placement/14.jpeg'),
                        isShowImg: false,
                        site: '腾讯ADX二期',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 1
                    },
                    {
                        id: 16,
                        sku_name: '头条联盟直投-激励视频（多样式）',
                        img: require('../../../../assets/images/admin/placement/15.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 17,
                        sku_name: '1280*720视频（头条信息流视频）CPC',
                        img: require('../../../../assets/images/admin/placement/15.png'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 18,
                        sku_name: '690*388图文（头条视频APP-信息流大图）tCPA',
                        img: require('../../../../assets/images/admin/placement/16.jpeg'),
                        isShowImg: false,
                        site: '头条视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 19,
                        sku_name: '228*150三图一文（今日头条APP-信息流三图）tCPA',
                        img: require('../../../../assets/images/admin/placement/17.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 20,
                        sku_name: '720*1280视频（头条联盟直投-原生视频）CPC',
                        img: require('../../../../assets/images/admin/placement/18.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 21,
                        sku_name: '720*1280视频（今日头条APP-小视频）CPC',
                        img: require('../../../../assets/images/admin/placement/19.png'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 22,
                        sku_name: '228*150三图一文（今日头条联盟-信息流三图）CPC',
                        img: require('../../../../assets/images/admin/placement/20.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 23,
                        sku_name: '228*150图文（今日头条联盟-信息流小图）CPC',
                        img: require('../../../../assets/images/admin/placement/20.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 24,
                        sku_name: '690*388图文（今日头条联盟-信息流大图）CPC',
                        img: require('../../../../assets/images/admin/placement/21.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 25,
                        sku_name: '1080*1920图文（今日头条联盟-开屏）CPC',
                        img: require('../../../../assets/images/admin/placement/22.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 26,
                        sku_name: '228*150图文（今日头条联盟adx-小图）转CPC',
                        img: require('../../../../assets/images/admin/placement/23.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 27,
                        sku_name: '228*150三图一文（今日头条联盟adx-三图）转CPC',
                        img: require('../../../../assets/images/admin/placement/24.png'),
                        isShowImg: false,
                        site: '今日头条联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 28,
                        sku_name: '690X388单图（西瓜视频APP-视频后贴片）CPC',
                        img: require('../../../../assets/images/admin/placement/25.png'),
                        isShowImg: false,
                        site: '西瓜视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 29,
                        sku_name: '690X388单图（西瓜视频APP-视频后贴片）转CPC',
                        img: require('../../../../assets/images/admin/placement/25.png'),
                        isShowImg: false,
                        site: '西瓜视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 30,
                        sku_name: '720*1280视频（抖音视频APP-竖版视频）CPC',
                        img: require('../../../../assets/images/admin/placement/26.jpeg'),
                        isShowImg: false,
                        site: '抖音短视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 31,
                        sku_name: '690*388图文（头条视频APP-信息流大图）转CPC',
                        img: require('../../../../assets/images/admin/placement/27.jpeg'),
                        isShowImg: false,
                        site: '头条视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 32,
                        sku_name: '690*388图文（头条视频APP-信息流大图）CPC',
                        img: require('../../../../assets/images/admin/placement/28.jpeg'),
                        isShowImg: false,
                        site: '头条视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 33,
                        sku_name: '690*388图文（西瓜视频APP-信息流视频大图）CPC',
                        img: require('../../../../assets/images/admin/placement/29.jpeg'),
                        isShowImg: false,
                        site: '西瓜视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 34,
                        sku_name: '540*870图文（火山小视频信息流竖版）转CPC',
                        img: require('../../../../assets/images/admin/placement/30.jpeg'),
                        isShowImg: false,
                        site: '火山小视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 35,
                        sku_name: '540*870竖图（火山小视频-信息流）CPC',
                        img: require('../../../../assets/images/admin/placement/31.jpeg'),
                        isShowImg: false,
                        site: '火山小视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 36,
                        sku_name: '720*1280视频（火山视频APP-竖版视频）转CPC',
                        img: require('../../../../assets/images/admin/placement/32.jpeg'),
                        isShowImg: false,
                        site: '火山小视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 37,
                        sku_name: '720*1280视频（抖音视频APP-竖版视频）转CPC',
                        img: require('../../../../assets/images/admin/placement/33.jpeg'),
                        isShowImg: false,
                        site: '抖音短视频',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 38,
                        sku_name: '690*388大图（今日头条APP-视频后贴片）CPC',
                        img: require('../../../../assets/images/admin/placement/34.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 39,
                        sku_name: '228*150图文（今日头条APP-视频相关推荐）转CPC',
                        img: require('../../../../assets/images/admin/placement/35.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 40,
                        sku_name: '228*150图文（今日头条APP-视频相关推荐）CPC',
                        img: require('../../../../assets/images/admin/placement/35.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 41,
                        sku_name: '690*286图文（今日头条APP-详情页大图）转CPC',
                        img: require('../../../../assets/images/admin/placement/36.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 42,
                        sku_name: '228*150图文（今日头条APP-信息流小图）转CPC',
                        img: require('../../../../assets/images/admin/placement/37.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 43,
                        sku_name: '228*150图文（今日头条APP-信息流小图）转CPC',
                        img: require('../../../../assets/images/admin/placement/38.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 44,
                        sku_name: '228*150三图一文（今日头条APP-信息流三图）CPC',
                        img: require('../../../../assets/images/admin/placement/39.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 45,
                        sku_name: '690X286图文（今日头条APP-详情页大图）CPC',
                        img: require('../../../../assets/images/admin/placement/36.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 46,
                        sku_name: '228X150图文（今日头条APP-信息流小图）CPC',
                        img: require('../../../../assets/images/admin/placement/38.jpeg'),
                        isShowImg: false,
                        site: '今日头条直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 2
                    },
                    {
                        id: 47,
                        sku_name: '370*245图文（百度联盟-信息流小图）CPC',
                        img: require('../../../../assets/images/admin/placement/39.jpeg'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 48,
                        sku_name: '1280*720视频（百度联盟-信息流视频）CPC',
                        img: require('../../../../assets/images/admin/placement/40.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 49,
                        sku_name: '600*300图文（百度联盟-信息流大图）CPC',
                        img: require('../../../../assets/images/admin/placement/41.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 50,
                        sku_name: '1080*1920单图（百度联盟-开屏大图）CPC',
                        img: require('../../../../assets/images/admin/placement/42.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 51,
                        sku_name: '1280*720视频（好看视频—信息流视频）CPC',
                        img: require('../../../../assets/images/admin/placement/43.png'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 52,
                        sku_name: '1280*720视频（手机百度—信息流视频）CPC',
                        img: require('../../../../assets/images/admin/placement/44.png'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 53,
                        sku_name: '1140*640图文（百度联盟-信息流大图）CPC',
                        img: require('../../../../assets/images/admin/placement/45.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 54,
                        sku_name: '370*245三图一文（百度联盟-信息流三图）CPC',
                        img: require('../../../../assets/images/admin/placement/46.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 55,
                        sku_name: '640*960单图（百度联盟-开机屏大图）CPC',
                        img: require('../../../../assets/images/admin/placement/47.png'),
                        isShowImg: false,
                        site: '百度联盟',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 56,
                        sku_name: '1140x640图文（手机百度APP-列表页大图）CPC',
                        img: require('../../../../assets/images/admin/placement/48.jpeg'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 57,
                        sku_name: '370x245图文（手机百度APP-详情页小图）CPC',
                        img: require('../../../../assets/images/admin/placement/49.jpeg'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 58,
                        sku_name: '370x245三图一文（手机百度APP-详情页三图）CPC',
                        img: require('../../../../assets/images/admin/placement/50.jpeg'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 59,
                        sku_name: '1140x640图文（手机百度APP-详情页大图）CPC',
                        img: require('../../../../assets/images/admin/placement/51.jpeg'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 60,
                        sku_name: '370*245图文（手机百度APP-信息流小图）CPC',
                        img: require('../../../../assets/images/admin/placement/52.jpeg'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 61,
                        sku_name: '370*245三图一文（手机百度APP-信息流三图）CPC',
                        img: require('../../../../assets/images/admin/placement/53.png'),
                        isShowImg: false,
                        site: '百度信息流直投',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 3
                    },
                    {
                        id: 62,
                        sku_name: '450*450图文（快手APP-快享便利贴）CPC',
                        img: require('../../../../assets/images/admin/placement/54.png'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    },
                    {
                        id: 63,
                        sku_name: '快手APP-发现页视频信息流-滑滑板样式 720*1280',
                        img: require('../../../../assets/images/admin/placement/55.jpeg'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    },
                    {
                        id: 64,
                        sku_name: '1280*720视频（快手APP-发现页视频信息流）CPC',
                        img: require('../../../../assets/images/admin/placement/56.png'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    },
                    {
                        id: 65,
                        sku_name: '720*1280视频（快手APP-发现页视频信息流）CPC',
                        img: require('../../../../assets/images/admin/placement/57.png'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    },
                    {
                        id: 66,
                        sku_name: '720*720视频（快手APP-发现页视频信息流）CPC',
                        img: require('../../../../assets/images/admin/placement/58.png'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    },
                    {
                        id: 67,
                        sku_name: '720*960视频（快手APP-发现页视频信息流）CPC',
                        img: require('../../../../assets/images/admin/placement/59.png'),
                        isShowImg: false,
                        site: '快手APP',
                        exposure: '',
                        click_unit_price: void 0,
                        click_rate: void 0,
                        ecpm: void 0,
                        prompt: '',
                        type: 4
                    }
                ],
                //导入投放位置
                placement_excel: '',
                excelFile: '',
            }
        },
        created() {
            if (this.$route.query.id) {
                this.getPlacementDetail();
            }
        },
        methods: {
            //获取投放位置详情
            getPlacementDetail() {
                let param = {
                    id: this.$route.query.id
                }
                advertisingInfo(param).then(res => {
                    this.advertising_name = res.data.advertising_name;
                    this.placementTable = JSON.parse(res.data.advertising_data);
                }).catch(err => {
                    console.log(err);
                })
            },
            //导入数据
            importPlacement() {
                this.$refs.excelInput.click();
            },
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 200) {
                    this.$message.warning('文件超过200M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.excelFile = fileList;
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.placement_excel = "data:excel/*," + url;
                    }
                }
                let formData = new FormData();
                formData.append('advertising_name', this.advertising_name);
                formData.append('file', this.excelFile);
                advertisingImportData(formData).then((res) => {
                    this.placementTable = res.data;
                    this.$message.success(res.msg);
                    this.$refs.excelInput.value = "";
                }).catch((err) => {
                    console.log(err);
                    this.$refs.excelInput.value = '';
                });
            },
            //导出数据
            exportPlacement() {
                let param = {
                    keywords: this.placementTable
                }
                advertisingSetCache(param).then((res) => {
                    this.$message.success('导出成功，正在下载');
                    window.location.href = localStorage.getItem('hosturl') + 'advertising/exportData';
                }).catch((err) => {
                    console.log(err);
                });
            },
            //下载模板
            downloadPlacementTemplate() {
                window.location.href = localStorage.getItem('hosturl') + 'template/placement_template.xls';
            },
            //失焦事件
            saveItem(val) {
                if (val === '' || val === null) {
                    this.$message.warning('请填写相关数据');
                    return false;
                }
            },
            //输入的点击单价
            inputVal(row) {
                if (row.type === 1) {
                    if (!Number(row.click_unit_price >= 0.5)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.5且小于等于10！');
                        return false;
                    }
                    if (Number(10 < row.click_unit_price)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.5且小于等于10！');
                        return false;
                    }
                }
                if (row.type === 2) {
                    if (Number(row.click_unit_price === 0)) {
                        row.click_unit_price = 0;
                    }
                    if (Number(row.click_unit_price < 0)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0且小于等于10！');
                        return false;
                    }
                    if (Number(10 < row.click_unit_price)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0且小于等于10！');
                        return false;
                    }
                }
                if (row.type === 3) {
                    if (!Number(row.click_unit_price >= 0.2)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.2且小于等于20！');
                        return false;
                    }
                    if (Number(20 < row.click_unit_price)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.2且小于等于20！');
                        return false;
                    }
                }
                if (row.type === 4) {
                    if (!Number(row.click_unit_price >= 0.1)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.1且小于等于10！');
                        return false;
                    }
                    if (Number(10 < row.click_unit_price)) {
                        row.click_unit_price = null;
                        this.$message.warning('输入的点击单价必须大于等于0.1且小于等于10！');
                        return false;
                    }
                }
            },
            //输入的点击率必须大于0且小于等于100
            inputVals(val) {
                if (val === Number(val)) {
                    if (Number(100 <= val && val > 0)) {
                        this.$message.warning('输入的点击率必须大于0且小于等于100！');
                        return false;
                    }
                } else {
                    this.$message.warning('输入的点击率必须是数字！');
                }

            },
        }
    }
</script>

<style scoped lang="scss">
    .create-content{
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        .create-content-top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .create-content-left{
                /*::v-deep.el-button--primary.is-plain {*/
                /*    color: #1CB064;*/
                /*    background: transparent;*/
                /*    border-color: #a4dfc1;*/
                /*}*/
            }
            .create-content-right{

                display: flex;
                align-items: center;
                .label-name{
                    min-width: 100px;
                }
            }
            .name-input {
                width: 325px;
                ::v-deep .el-input__inner {
                    border-radius: 0;
                }
            }
        }
        .success-content{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 140px;
            .text{
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }
        }
    }
    .create-table {
        display: flex;
        flex-direction: column;
    }
    .bottom-btn{
        text-align: center;
        padding: 20px;
    }
    .customTable {
        .text {
            flex: 1;
            width: 1%;
            text-align: left;
        }
        .one-click-creativity {
            width: 74px;
            height: 24px;
            background-color: #2D98F4;
            color: #fff;
            font-size: 14px;
            line-height: 24px;
            margin-left: 20px;
        }
        ::v-deep {
            .el-table__row {
                &:nth-child(16), &:nth-child(17) {
                    td:first-child > .cell {
                        /*display: flex;*/
                        img.icon {
                            display: block;
                        }
                    }
                }
                .ad-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            td:first-child > .cell {
                /*display: flex;*/
                img.icon {
                    display: none;
                }
            }
        }
    }
    ::v-deep .input-error {
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
</style>