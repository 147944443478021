<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>投放位置</el-breadcrumb-item>
            <el-breadcrumb-item>{{breadTitle}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper steps-mini">
            <div class="step-item" :class="{current: [1, 2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建投放位置数据</div>
            </div>
            <div class="step-item" :class="{current: [2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>

        <PlacementCreateStepOne v-if="stepActive === 1" ref="placementData"/>
        <div class="creating-success" v-if="stepActive === 2">
            <img :src="require('../../../assets/images/admin/success.png')" alt="">
            <div class="text">保存成功</div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" v-if="stepActive !== 2">返回</el-button>
            <el-button @click="nextBtn(stepActive)" type="primary" v-if="stepActive !== 2">下一步</el-button>
            <el-button @click="prevBtn(stepActive)" type="primary" v-else>返回列表</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import PlacementCreateStepOne from '@/components/admin/practicalExercises/placement/PlacementCreateStepOne'
    import {advertisingAdd, advertisingEdit} from '@/utils/apis'

    export default {
        name: "PlacementCreate",
        components: {
            PlacementCreateStepOne,
        },
        data() {
            return {
                //步骤
                stepActive: 1,
                //面包屑标题
                breadTitle: '添加投放位置',
                id: this.$route.query.id || ''
            }
        },
        created() {
          if (this.id) {
              this.breadTitle = '编辑投放位置';
          }
        },
        methods: {
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1);
                } else if (index === 2) {
                    this.$router.push('/placement/index');
                } else {
                    this.stepActive = index - 1;
                }
            },
            // 下一步按钮
            nextBtn(index) {
                // 第一步的下一步
                if (index === 1) {
                    let placementTable = this.$refs.placementData.placementTable;
                    let advertising_name = this.$refs.placementData.advertising_name;
                    if (!advertising_name) {
                        return this.$message.warning('请输入投放位置名称');
                    }
                    //验证输入框，没填报红框
                    this.$refs.placementData.placementTable.forEach((item, index) => {
                        for (const key in item) {
                            if (key === 'exposure' || key === 'click_rate' || key === 'click_unit_price' || key === 'ecpm') {
                                if (!item[key]) {
                                    this.$refs.placementData.$refs[`placementTable_${key}_${index}`].$el.classList.add('isError');
                                } else {
                                    this.$refs.placementData.$refs[`placementTable_${key}_${index}`].$el.classList.remove('isError');
                                }
                            }
                            //单独判断输入的点击单价
                            if (key === 'click_unit_price') {
                                if (!item[key]) {
                                    if (item.type === 2) {
                                        if (item.click_unit_price === 0) {
                                            this.$refs.placementData.$refs[`placementTable_${key}_${index}`].$el.classList.remove('isError');
                                        } else {
                                            this.$refs.placementData.$refs[`placementTable_${key}_${index}`].$el.classList.add('isError');
                                        }
                                    }
                                }
                            }
                        }
                    })
                    //验证输入框，没填出现消息弹窗
                    let dialog_flag = 0;
                    placementTable.forEach(item => {
                        if (!item.exposure) {
                            dialog_flag++;
                            if (dialog_flag === 1) {
                                return this.$message.warning('请输入曝光量级');
                            }
                            return;
                        }
                        if (item.type === 2 && item.click_unit_price === 0) {
                            dialog_flag = 0;
                        } else {
                            if (!item.click_unit_price) {
                                dialog_flag++;
                                if (dialog_flag === 1) {
                                    return this.$message.warning('请输入点击单价');
                                }
                                return;
                            }
                        }
                        if (!item.click_rate) {
                            dialog_flag++;
                            if (dialog_flag === 1) {
                                return this.$message.warning('请输入点击率');
                            }
                            return;
                        }
                        if (!item.ecpm) {
                            dialog_flag++;
                            if (dialog_flag === 1) {
                                return this.$message.warning('请输入ECPM');
                            }
                            return;
                        }
                    });
                    if (dialog_flag) {
                        return;
                    }
                    let param = {
                        advertising_name: advertising_name,
                        advertising_data: placementTable
                    }
                    if (this.id) {
                        param.id = this.id;
                        advertisingEdit(param).then(res => {
                            this.stepActive = 2;
                        }).catch(err => {
                            console.log(err);
                        })
                    } else {
                        advertisingAdd(param).then(res => {
                            this.stepActive = 2;
                        }).catch(err => {
                            console.log(err);
                        })
                    }
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .bottom-btn {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    .creating-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        img {
            width: 240px;
        }
        .text {
            color: #333333;
            font-size: 24px;
            line-height: 1;
            margin: 32px 0 36px;
        }
    }
</style>